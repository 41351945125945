<template>
  <div>
    <h4 class="subtitle-form">{{ $t("inputStock.details.product_type") }}</h4>
    <Dropdown
      ref="dropdown"
      class="shape-custom-select"
      :options="productTypesByFamily"
      label="designation"
      :value="currentProductType"
      @input="type => (type ? onProductTypeChoice(type.id) : onProductTypeChoice(type))"
      required
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Dropdown from "@/components/inputstock/details/Dropdown.vue";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import ProductSelectionMixin from "@/mixins/ProductSelectionMixin";
import { EventBus } from "@/eventBus.js";

export default {
  mixins: [DetailUpdateMixin, ProductSelectionMixin],
  data() {
    return {
      test:'',
      previousType: null,
    };
  },
  components: {
    Dropdown,
  },
  async created() {
    this.loadOn();

    this.clearProductTypeData();

    await Promise.all([this.getProductFamilies(), this.getProductTypesByFamily({ idProdFamily: this.familyCode })]);

    if (this.currentProductType) {
      this.loadProductTypeData();
    }
    this.previousType = this.currentProductType;

    this.loadOff();
  },
  computed: {
    ...mapGetters({
      rows: "researchResults",
      productFamilies: "productFamilies",
      productTypesByFamily: "productTypesByFamily",
      researchResults: "researchResults",
    }),

    familyCode() {
      return this.currentEditedEntry.productFamily_code || this.currentEntry.productFamily_code;
    },
  },
  methods: {
    ...mapActions(["getProductFamilies", "getProductTypesByFamily"]),
    async onProductTypeChoice(type) {
      this.test = type;
      await this.$store.dispatch(this.storeEditAction, {
        productType: type,
      });

      if (type) this.loadProductTypeData();
      else {
        this.clearProductTypeData();
      }

      if (this.previousType != null) EventBus.$emit("resetProductSelection");

      this.previousType = type;
    },
  },
  watch: {
    currentProductType(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.onProductTypeChoice(newValue.id);
        this.$refs.dropdown.setValue(newValue);
      }
    },
    productTypesByFamily: {
      handler() {
        // S'il y'a un seul type de produit proposé, il est directement sélectionné
        if (this.productTypesByFamily && this.productTypesByFamily.length === 1) {
          this.onProductTypeChoice(this.productTypesByFamily[0].id)
        }
      }
    }
  },
};
</script>
